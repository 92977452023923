<template>
    <div>
        <input ref="upload" style="display: none" type="file" :multiple="true" @change="onChange" />
        <a-space>
            <a-button type="primary" :loading="loading" @click="onClick">
                {{ list.length > 0 ? '继续上传' : '点击上传' }}
            </a-button>
            <span style="color: #999">大文件上传时间较长，请耐心等待</span>
        </a-space>
    </div>
</template>
  
<script>
import { uploadFile } from "@/api/upload";
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        onClick() {
            this.$refs.upload.click();
        },

        onChange(e) {
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("files", files[i]);
            }

            this.loading = true;

            uploadFile(data)
                .then((res) => {
                    console.log("upload res", res);
                    if (Array.isArray(res)) {
                        this.$emit("change", [...this.list, ...res]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                    e.target.value = null;
                });
        },

    },
};
</script>