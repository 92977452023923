var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.index !== -1 ? '修改科研成果' : '添加科研成果',"footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.text.name,
            rules: [{ required: true, message: '请输入！' }],
          },
        ]),expression:"[\n          'name',\n          {\n            initialValue: text.name,\n            rules: [{ required: true, message: '请输入！' }],\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            initialValue: _vm.text.type,
          },
        ]),expression:"[\n          'type',\n          {\n            initialValue: text.type,\n          },\n        ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.doc_achievement_type),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"说明"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            initialValue: _vm.text.remark,
          },
        ]),expression:"[\n          'remark',\n          {\n            initialValue: text.remark,\n          },\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"附件"}},[_c('FileUpload',{attrs:{"multiple":false},on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("上传")])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.attachmentList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":text.completePath,"target":"_blank"}},[_vm._v(_vm._s(text.name))])]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }