<template>
  <a-modal
    :visible="visible"
    :title="index !== -1 ? '修改科研成果' : '添加科研成果'"
    :footer="null"
    @cancel="cancel"
  >
    <a-form :form="form" :colon="false" @submit="handleSubmit">
      <a-form-item label="名称">
        <a-input
          v-decorator="[
            'name',
            {
              initialValue: text.name,
              rules: [{ required: true, message: '请输入！' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="类型">
        <a-select
          style="width: 100%"
          v-decorator="[
            'type',
            {
              initialValue: text.type,
            },
          ]"
        >
          <a-select-option
            v-for="item in doc_achievement_type"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>

      <a-form-item label="说明">
        <a-textarea
          v-decorator="[
            'remark',
            {
              initialValue: text.remark,
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="附件">
        <FileUpload :multiple="false" @uploaded="uploaded">
          <a-button type="primary">上传</a-button>
        </FileUpload>

        <Padding />

        <a-table bordered :data-source="attachmentList" :pagination="false">
          <a-table-column title="文件名">
            <template slot-scope="text">
              <a :href="text.completePath" target="_blank">{{ text.name }}</a>
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center">
            <template slot-scope="text, record, index">
              <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
            </template>
          </a-table-column>
        </a-table>
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import FileUpload from "@/components/file-upload";

export default {
  components: {
    FileUpload,
  },

  props: {
    visible: {
      type: Boolean,
    },
    text: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
    },
    list: {
      type: Array,
      default: () => [],
    },
    doc_achievement_type: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      attachmentList: [],
    };
  },

  watch: {
    index() {
      this.attachmentList = Array.isArray(this.text.attachmentList)
        ? this.text.attachmentList
        : [];
    },
  },
  methods: {
    uploaded(list) {
      this.attachmentList = [...this.attachmentList, ...list];
    },
    remove(index) {
      this.attachmentList.splice(index, 1);
    },

    cancel() {
      this.form.resetFields();
      this.$emit("cancel");
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.attachmentList.length) {
            this.$message.error("请上传附件");
            return;
          }

          if (this.index > -1) {
            const list = this.list;
            list[this.index] = {
              ...values,
              attachmentList: this.attachmentList,
            };
            this.$emit("change", list);
          } else {
            this.$emit("change", [
              ...this.list,
              {
                ...values,
                attachmentList: this.attachmentList,
              },
            ]);
          }
          this.$emit("cancel");
        }
      });
    },
  },
};
</script>